import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { RecoilAtomKeys } from 'src/utils/recoil/keys';

const userPhotoState = atom<string>({
  key: RecoilAtomKeys.USER_PHOTO,
  default: '',
})
const useUserPhoto = () => {
  const userPhoto = useRecoilValue(userPhotoState)

  return { userPhoto }
}
const useUserPhotoSetter = () => {
  const setUserPhoto = useSetRecoilState(userPhotoState)
  return { setUserPhoto }
}

export { useUserPhoto, useUserPhotoSetter }
