import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { RecoilAtomKeys } from 'src/utils/recoil/keys';

const loaderState = atom<string>({
  key: RecoilAtomKeys.LOADER_DISPLAY_STATE,
  default: '',
})
const useLoaderState = () => {
  const isLoaderShown = useRecoilValue(loaderState)
  return { isLoaderShown }
}
const useLoaderStateSetter = () => {
  const setLoaderState = useSetRecoilState(loaderState)
  return { setLoaderState }
}

export { useLoaderState, useLoaderStateSetter }
