import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { RecoilAtomKeys } from 'src/utils/recoil/keys';

const isRegisteredState = atom<boolean>({
  key: RecoilAtomKeys.USER_REGISTERED,
  default: false,
})
const useIsRegistered = () => {
  const isRegistered = useRecoilValue(isRegisteredState)
  return { isRegistered }
}
const useIsRegisteredSetter = () => {
  const setIsRegistered = useSetRecoilState(isRegisteredState)
  return { setIsRegistered }
}

export { useIsRegistered, useIsRegisteredSetter }
