import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { RecoilAtomKeys } from 'src/utils/recoil/keys';

const userNameState = atom<string>({
  key: RecoilAtomKeys.USER_NAME,
  default: '',
})
const useUserName = () => {
  const userName = useRecoilValue(userNameState)

  return { userName }
}
const useUserNameSetter = () => {
  const setUserName = useSetRecoilState(userNameState)
  return { setUserName }
}

export { useUserName, useUserNameSetter }
