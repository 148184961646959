import { useRouter } from 'next/dist/client/router'
import { LinkProps } from 'next/link'
import React, { FC } from 'react'
import styles from '../style.module.scss'

type ButtonProps = {
  size?: string
  color?: string
}

type Props = LinkProps &
  Omit<ButtonProps, 'size' | 'color'> & {
    color?:
      | 'blue'
    size?: 'medium' | 'large' | 'small' | 'lg:Small_mq:Large'
  }

const LinkButton: FC<Props> = ({ size = 'medium', ...props }) => {
  const router = useRouter()

  const onClick = () => {
    router.push(props.href)
  }
  return (
    <button
      role='button'
      onClick={onClick}
      {...props}
      data-color={props.color}
      data-size={size}
      className={styles.button}
    >
      {props.children}
    </button>
  )
}

export default LinkButton
