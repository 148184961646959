import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { RecoilAtomKeys } from 'src/utils/recoil/keys';

const fetchingState = atom<boolean>({
  key: RecoilAtomKeys.USER_FETCHING,
  default: true,
})
const useFetching = () => {
  const fetching = useRecoilValue(fetchingState)
  return { fetching }
}
const useEndFetching = () => {
  const setter = useSetRecoilState(fetchingState)
  const endFetching = () => {
    setter(false)
  }
  return { endFetching }
}

export { useFetching, useEndFetching }
