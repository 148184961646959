import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { RecoilAtomKeys } from 'src/utils/recoil/keys';

const userMailState = atom<string>({
  key: RecoilAtomKeys.USER_MAIL,
  default: '',
})
const useUserMail = () => {
  const userMail = useRecoilValue(userMailState)
  return { userMail }
}
const useUserMailSetter = () => {
  const setUserMail = useSetRecoilState(userMailState)
  return { setUserMail }
}

export { useUserMail, useUserMailSetter }
