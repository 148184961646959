import { useEffect, useState } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { RecoilAtomKeys } from 'src/utils/recoil/keys';

import { useFetching } from './userFetching';

const userUidState = atom<string>({
  key: RecoilAtomKeys.USER_UID,
  default: '',
})
const useUserUid = () => {
  const userUid = useRecoilValue(userUidState)
  return { userUid }
}
const useUserUidSetter = () => {
  const setUserUid = useSetRecoilState(userUidState)
  return { setUserUid }
}
const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const uid = useRecoilValue(userUidState)
  const { fetching } = useFetching()
  useEffect(() => {
    if (fetching) {
      return
    }
    setIsAuthenticated(!!uid)
  }, [uid])
  return { isAuthenticated }
}

export { useUserUid, useUserUidSetter, useIsAuthenticated }
